import ExcelJS from 'exceljs'

export const kpiDataDownload = async (
  yearMonth,
  fields,
  tableData,
  summaryData
) => {
  const title = `KPI ${yearMonth} 報告`

  const workbook = new ExcelJS.Workbook() // create excel

  const sheet = workbook.addWorksheet(title)

  const titleList = fields.map((obj) => obj.label) // 案場名稱, 案場容量, 目標 PR 值 (%), ...
  const columnKeyList = fields.map((obj) => obj.key) // factoryName, capacity, pr1, ...

  // style
  const colorBlue = { color: { argb: 'FF1fa3c8' } }
  const colorOrange = { color: { argb: 'FFe67a00' } }
  const colorGreen = { color: { argb: 'FF0fb468' } }
  const colorRed = { color: { argb: 'FFdc3545' } }
  const colorGrey = { color: { argb: 'FF555555' } }
  const borderStyle = { style: 'thin', ...colorGrey }
  const tableBorder = {
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle
  }
  const alignCenter = { vertical: 'middle', horizontal: 'center' }
  const alignRight = { vertical: 'middle', horizontal: 'right' }
  // const fillGray = {
  //   type: 'pattern',
  //   pattern: 'solid',
  //   fgColor: { argb: 'FFf6f7f5' }
  // }

  // tableData table -------------------------------------------------
  // columnName I - L 數值顏色
  function setDataColor (column) {
    column.eachCell((cell, rowNumber) => {
      if (rowNumber > 8 && cell.value < 100) {
        sheet.getCell(cell.address).font = colorRed
      }
      if (rowNumber > 8 && cell.value > 100) {
        sheet.getCell(cell.address).font = colorGreen
      }
    })
  }

  columnKeyList.forEach((columnKey, index) => {
    const columnName = String.fromCharCode(65 + index)
    const column = sheet.getColumn(columnName)
    const columnData = tableData.map((obj) => obj[columnKey] ?? '--')
    const summaryBlankFront = ['', '', '', '', '', ''] // 先空出 summary 需要的 1 - 6 行 (row cell)

    if (columnName === 'I') {
      sheet.mergeCells('I7:J7')

      column.values = [...summaryBlankFront, '', '', ...columnData] // 覆蓋 A6, A7... 單元格值 需 string | string[]

      // 單一 cell 不同 font color (富文本)
      sheet.getCell('I8').value = {
        richText: [
          { text: '實際值', font: colorBlue },
          { text: ' / ' },
          { text: '目標值', font: colorOrange }
        ]
      }

      setDataColor(column)
    } else if (columnName === 'J') {
      column.values = [
        ...summaryBlankFront,
        'PR 值 達標狀況 (%)',
        '',
        ...columnData
      ]
      // 另
      // 合併後的 cell 應賦值於較後的 cell，ex 'I6:J6' 值應置入 J6
      // sheet.getCell('J6).value = 'PR 值達標狀況 (%)'

      sheet.getCell('J8').value = {
        richText: [
          { text: '實際值', font: colorBlue },
          { text: ' / ' },
          { text: '保證值', font: colorGreen }
        ]
      }

      setDataColor(column)
    } else if (columnName === 'K') {
      sheet.mergeCells('K7:L7')

      column.values = [...summaryBlankFront, '', '', ...columnData]

      sheet.getCell('K8').value = {
        richText: [
          { text: '實際值', font: colorBlue },
          { text: ' / ' },
          { text: '目標值', font: colorOrange }
        ]
      }

      setDataColor(column)
    } else if (columnName === 'L') {
      column.values = [
        ...summaryBlankFront,
        '發電量 達標狀況 (%)',
        '',
        ...columnData
      ]

      sheet.getCell('L8').value = {
        richText: [
          { text: '實際值', font: colorBlue },
          { text: ' / ' },
          { text: '保證值', font: colorGreen }
        ]
      }

      setDataColor(column)
    } else {
      // A - H
      sheet.mergeCells(`${columnName}7:${columnName}8`)
      column.values = [
        ...summaryBlankFront,
        '',
        titleList[index],
        ...columnData
      ]
      // 另
      // 合併後的 cell 應賦值於較後的 cell，ex 'A6:A7' 值應置入 A7
      // sheet.getCell(`${columnName}7`).value = titleList[index]
    }

    // 除了 column A 其餘內容置右
    // 需要置中的 後面 style settings 會覆蓋掉這裡的設定
    if (columnName !== 'A') column.alignment = alignRight
    // 欄寬
    column.width = 20
  })

  // summary table ----------------------------------------------
  sheet.mergeCells(`A1:B1`)
  sheet.getCell('B1').value = '符合該年月份計算的案場數'

  sheet.mergeCells(`C1:E1`)
  sheet.getCell('E1').value = '目標值 案場數合格狀況'

  sheet.mergeCells(`F1:H1`)
  sheet.getCell('H1').value = '保證值 案場數合格狀況'

  sheet.getCell('A2').value = '年份'
  sheet.getCell('A3').value = yearMonth.split('-')[0]
  sheet.mergeCells(`A4:A5`)
  sheet.getCell('A5').value = '合格率統計項目'

  sheet.getCell('B2').value = '月份'
  sheet.getCell('B3').value = yearMonth.split('-')[1]
  sheet.getCell('B4').value = 'PR值 狀況統計'
  sheet.getCell('B5').value = '發電量 狀況統計'

  sheet.mergeCells(`C2:C3`)
  sheet.getCell('C3').value = '符合計算的\n案場數'
  sheet.getCell('C4').value = summaryData.PRAchievedCount
  sheet.getCell('C5').value = summaryData.powerAchievedCount

  sheet.mergeCells(`D2:D3`)
  sheet.getCell('D3').value = '目標值達標的\n案場數'
  sheet.getCell('D4').value = summaryData.PRAchievedQualifiedCount
  sheet.getCell('D5').value = summaryData.powerAchievedQualifiedCount

  sheet.mergeCells(`E2:E3`)
  sheet.getCell('E3').value = {
    richText: [
      { text: '達標案場數', font: colorBlue },
      { text: ' /\n' },
      { text: '符合計算案場數', font: colorOrange },
      { text: ' (%)' }
    ]
  }
  sheet.getCell('E4').value = summaryData.PRAchievementCountRatio
  sheet.getCell('E5').value = summaryData.powerAchievementCountRatio

  sheet.mergeCells(`F2:F3`)
  sheet.getCell('F3').value = '符合計算的\n案場數'
  sheet.getCell('F4').value = summaryData.PRGuaranteeCount
  sheet.getCell('F5').value = summaryData.powerGuaranteeCount

  sheet.mergeCells(`G2:G3`)
  sheet.getCell('G3').value = '保證值達標的\n案場數'
  sheet.getCell('G4').value = summaryData.PRGuaranteeQualifiedCount
  sheet.getCell('G5').value = summaryData.powerGuaranteeQualifiedCount

  sheet.mergeCells(`H2:H3`)
  sheet.getCell('H3').value = {
    richText: [
      { text: '達標案場數', font: colorBlue },
      { text: ' /\n' },
      { text: '符合計算案場數', font: colorGreen },
      { text: ' (%)' }
    ]
  }
  sheet.getCell('H4').value = summaryData.PRGuaranteeCountRatio
  sheet.getCell('H5').value = summaryData.powerGuaranteeCountRatio

  // style settings --------------------------------------------------
  // 1. 標題/欄位 置中
  // getRow(num) / getRows(start, length)
  sheet.getRows(1, 8).forEach((row) => {
    row.alignment = alignCenter
  })

  // 2. 文字換行 (\n)
  // C3 - H3
  sheet.getRow(3).eachCell((cell, colNumber) => {
    if (colNumber >= 3 && colNumber <= 8) {
      cell.alignment = { ...alignCenter, wrapText: true }
    }
  })

  // 3. summary table border
  // row 1-5 , column A-H (colNumber 1-8)
  sheet.getRows(1, 5).forEach((row) => {
    row.eachCell((cell, colNumber) => {
      if (colNumber <= 8) cell.border = tableBorder
    })
  })

  // 4. 標題背景色
  // sheet.getRow(1).fill = fillGray
  // sheet.getRow(2).fill = fillGray

  // 5. font color
  sheet.getCell('C3').font = colorBlue
  sheet.getCell('C8').font = colorBlue
  sheet.getCell('D3').font = colorOrange
  sheet.getCell('D8').font = colorOrange
  sheet.getCell('E8').font = colorGreen
  sheet.getCell('F3').font = colorBlue
  sheet.getCell('F8').font = colorBlue
  sheet.getCell('G3').font = colorGreen
  sheet.getCell('G8').font = colorOrange
  sheet.getCell('H8').font = colorGreen

  // 資料下載 ----------------------------------------------------------
  const attachment = await workbook.xlsx.writeBuffer()
  const url = window.URL.createObjectURL(new Blob([attachment]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `KPI 報告_${yearMonth}.xlsx`)
  link.click()
}
